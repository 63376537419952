import { Link } from "gatsby";
import React from "react";
import { trackBuyMeACoffee } from "../../../helpers/trackingEvents";
import { LinkSectionLink } from "../../../interfaces/linksPage";
import Button from "../../Button";
import { linkButtonStyles as styles } from "../styles";

const LinkButton: React.FC<LinkSectionLink> = ({ linkUrl, description }) => {
  const isBuyMeACoffee =
    linkUrl === "https://www.buymeacoffee.com/whymumsdontjump";
  return (
    <Link to={linkUrl}>
      <Button
        type="secondary"
        cssStyles={styles.button}
        onClick={isBuyMeACoffee ? () => trackBuyMeACoffee : undefined}
      >
        {description}
      </Button>
    </Link>
  );
};

export default LinkButton;
