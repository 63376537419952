import { EmotionStyles } from "../../interfaces/emotion";
import { variables } from "../../styles/variables";
const { spacing, centeredContainer } = variables;

export const styles: EmotionStyles = {
  container: {
    ...centeredContainer,
    overflow: "auto",
    padding: spacing.medium,
    paddingBottom: 80,
    flexDirection: "column"
  }
};

export const linkButtonStyles: EmotionStyles = {
  button: {
    width: "100%",
    padding: spacing.medium,
    marginBottom: spacing.large
  }
};
