import React from "react";
import { styles } from "./styles";
import { LinksLandingPage as LinksLandingPageProps } from "../../interfaces/linksLandingPage";
import LinkButton from "./components/LinkButton";

const LinksLandingPage: React.FC<LinksLandingPageProps> = ({ id, links }) => {
  return (
    <div css={styles.container}>
      {links && (
        <div>
          {links.map((link, key) => (
            <LinkButton {...link} key={`${id}_${key}`} />
          ))}
        </div>
      )}
    </div>
  );
};

export default LinksLandingPage;
