import React, { Fragment } from "react";
import { graphql } from "gatsby";
import GraphQLErrorList, {
  GraphQLError
} from "../components/graphql-error-list";
import SEO from "../components/SEO";
import { toPlainText } from "../helpers/content";
import LinksLandingPage from "../components/LinksLandingPage";
import { LinksLandingPage as LinksLandingPageInterface } from "../interfaces/linksLandingPage";
import { SiteInterface } from "../pages";

export const query = graphql`
  query CmsLinksLandingPageTemplateQuery($id: String!) {
    page: sanityLinksLandingPage(id: { eq: $id }) {
      id
      title
      links {
        description
        linkUrl
      }
    }
    site: sanitySiteSettings {
      title
      keywords
    }
  }
`;

interface LinksLandingPageTemplateProps {
  data: {
    page: LinksLandingPageInterface;
    site: Omit<SiteInterface, "description">;
  };
  errors: Array<GraphQLError>;
}

const LinksLandingPageTemplate: React.FC<LinksLandingPageTemplateProps> = ({
  data,
  errors
}) => {
  const site = data && data.site;
  const page = data && data.page;

  return (
    <Fragment>
      {errors && <SEO title="GraphQL Error" />}
      {page && site && (
        <SEO
          title={`${page.title || "Untitled"} | ${site.title}`}
          description={toPlainText(page._rawExcerpt)}
          keywords={site.keywords}
        />
      )}
      {errors && <GraphQLErrorList errors={errors} />}
      {page && <LinksLandingPage {...page} />}
    </Fragment>
  );
};

export default LinksLandingPageTemplate;
